import './App.scss'
import React, { useState, useEffect } from 'react'

function App () {
  const [scrolling, setScrolling] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleLinkClickToTerms = () => {
    window.open('https://ai-sora.ai/user-agreement/#/terms-service/', '_blank')
  }

  const handleLinkClickToPrivate = () => {
    window.open('https://ai-sora.ai/user-agreement/#/privacy-policy/', '_blank')
  }

  const handleLocateToAppStore = () => {
    window.open('https://apps.apple.com/us/app/attract-ai-photo-generator/id6479294894', '_blank')
  }

  return (
    <div className="App">
      <header className={scrolling ? "header header-scrolled" : "header"}>
        <div className='container'>
          <div className='navL'>
            <img src="./aa_nav_logo.png" alt="" />
          </div>
          <div className='navR'>
            <div
              onClick={handleLocateToAppStore}
              className='store'>App Store</div>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ backgroundColor: isHovered ? 'rgba(16, 244, 219, 0.6)' : 'rgba(16, 244, 219, 1)' }}
              className='download'>
              Download
              {isHovered && (
                <div className='qrCode'>
                  <div className='triangle'>
                    <img src="./triangle.png" alt="" />
                  </div>
                  <div className='qrcode'>
                    <img src="./qrcode.png" alt="" />
                  </div>
                  <div className='text'>
                    <div>
                      <img src="./scan.png" alt="" />
                    </div>
                    <div>Scan to download</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className='section'>
        <div className='decoration1'>
          <img src="./decoration1.png" alt="" />
        </div>
        <div className='decoration2'>
          <img src="./decoration2.png" alt="" />
        </div>
        <div className='left'>
          <img src="./vedio.webp" alt="" />
        </div>
        <div className='right'>
          <div className='icon'>
            <img src="./aa_icon.png" alt="" />
          </div>
          <div className='name'>Attract AI</div>
          <div className='desc'>Capture interest and transform selfies into connections</div>
        </div>
      </div>
      <footer className='footer'>
        <div className='container'>
          <div className='footerL'>
            <div className='title'>Attract AI</div>
            <div className='copyright'>Copyright © 2024 JOY AGE PTE.LTD.</div>
          </div>
          <div className='footerR'>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToPrivate}
              >Privacy Policy</div>
            </div>
            <div className='item'>
              <div className='link'
                onClick={handleLinkClickToTerms}
              >Terms of Use</div>
            </div>
          </div>
        </div>

      </footer>
    </div>
  )
}

export default App
